import { Box, Card, Flex, Progress, Text, useColorModeValue } from "@chakra-ui/react";
import { DocumentQuery } from "@metriport/api-sdk";

export const DocumentProgressContainer = ({ dqProgress }: { dqProgress: DocumentQuery }) => {
  const total = dqProgress.convert?.total ?? 0;
  const completed = (dqProgress.convert?.successful ?? 0) + (dqProgress.convert?.errors ?? 0);
  const progressValue = total ? (completed / total) * 100 : 0;

  const bgColor = useColorModeValue("white", "gray.900");

  if (dqProgress.convert?.status === "processing" && total > 0) {
    return (
      <Box
        w={"100%"}
        position="fixed"
        left={0}
        right={0}
        bottom={"100px"}
        display="flex"
        justifyContent="center"
      >
        <Card bg={bgColor} border={"solid #d3d3d3 1px"} p={5} w={500} mx={5}>
          <Flex mb={2} justifyContent={"space-between"}>
            <Text>Querying documents</Text>
            <Text>
              {completed} / {total}
            </Text>
          </Flex>
          <Progress colorScheme={"purple"} hasStripe value={progressValue} />
        </Card>
      </Box>
    );
  }

  return null;
};
