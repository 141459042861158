import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Button } from "../medical/shared-components/button";

type MedicalRequestModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const MedicalRequestModal = ({ isOpen, onClose }: MedicalRequestModalProps) => {
  const onModalClose = () => {
    onClose();
  };

  return (
    <VStack align="stretch" spacing={4}>
      <Modal isOpen={isOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent maxW={{ base: "90vw", xl: "40vw" }} pb={6}>
          <ModalHeader fontSize="xl" textAlign="center">
            Get started with Metriport
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={8}>
            <Text fontSize="md" textAlign="center" mb={6}>
              If you haven't talked to our team yet, book an intro call with us to get started.
            </Text>
            <Text fontSize="md" textAlign="center" mb={6}>
              Otherwise, hang tight while we setup your account!
            </Text>
            <Center>
              <Button
                onClick={() => {
                  window.open(
                    "https://calendly.com/colinelsinga/metriport-intro",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
                size="md"
              >
                Book an Intro Call
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default MedicalRequestModal;
