import { useDisclosure } from "@chakra-ui/react";
import { Product } from "../../api/products";
import { Button } from "../medical/shared-components/button";
import { formatter, formatterNoDecimal } from "../shared/util";
import MedicalRequestModal from "./medical-request-modal";
import ProductCard from "./product-card";

export default function MedicalAPIProduct({
  product,
  isActive,
  onSubscribe,
  isSubscribing,
}: {
  product: Product;
  isActive: boolean;
  onSubscribe: () => void;
  isSubscribing: boolean;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure({});

  const flatRate = product.flatRate ? formatterNoDecimal.format(product.flatRate / 100) : null;
  const pricePerUser = product.pricePerUser ? formatter.format(product.pricePerUser / 100) : null;

  const onFormOpen = () => {
    onOpen();
  };

  return (
    <>
      <ProductCard
        name={product.name}
        pricingDetailsUrl="https://www.metriport.com/medical#pricing"
        isActive={isActive}
        flatRate={flatRate}
        nOfUsers={product.upToAmount}
        pricePerUser={pricePerUser}
        footer={
          <Button
            size={"lg"}
            isLoading={isSubscribing}
            onClick={() => {
              if (isActive) {
                onSubscribe();
              } else {
                onFormOpen();
              }
            }}
          >
            {isActive ? "Manage Subscription" : "Get Started"}
          </Button>
        }
      />
      <MedicalRequestModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
