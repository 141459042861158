import { Condition, Encounter, Patient, Practitioner } from "@medplum/fhirtypes";
import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { SidePanelContent } from "../../side-panel/side-panel-content";
import { getReferenceResource, getResourcesFromBundle } from "../shared";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";
import { OccurenceDisplayPeriods } from "../shared/occurrences";
import { ReferencesDisplay } from "../shared/references";
import { SidePanelSection } from "../shared/sidepanel-section";
import { ConditionRowData, GroupedConditions, groupConditions } from "./table-data";

dayjs.extend(utc);

export function ConditionContent({
  mappedConsolidated,
  isImpersonating,
  ...rowContents
}: {
  isImpersonating?: boolean;
  mappedConsolidated: MappedConsolidatedResources | undefined;
} & ConditionRowData) {
  const patient = getResourcesFromBundle<Patient>(mappedConsolidated, "Patient")[0];
  const patientDob = patient?.birthDate ? dayjs.utc(patient.birthDate).format(ISO_DATE) : undefined;

  const conditions = getResourcesFromBundle<Condition>(mappedConsolidated, "Condition");
  const groupedConditions = groupConditions(conditions, patientDob);
  const selectedCondition = groupedConditions.find(
    conditions => conditions.mostRecentCondition.id === rowContents.id
  );

  if (!selectedCondition) {
    return null;
  }

  const mostRecentCondition = selectedCondition.mostRecentCondition;

  const valueString =
    mostRecentCondition?.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <SidePanelContent
      title="Condition"
      sourceDocument={{
        id: mostRecentCondition?.id ?? "",
        fileName: valueString,
      }}
      fhirJson={isImpersonating ? JSON.stringify(mostRecentCondition, null, 2) : undefined}
    >
      <ConditionDisplay
        condition={selectedCondition}
        tableRow={rowContents}
        mappedConsolidated={mappedConsolidated}
      />
    </SidePanelContent>
  );
}

function ConditionDisplay({
  condition,
  tableRow,
  mappedConsolidated,
}: {
  condition: GroupedConditions | undefined;
  tableRow: ConditionRowData;
  mappedConsolidated: MappedConsolidatedResources | undefined;
}) {
  if (!condition) return null;

  const encounterReference = getReferenceResource<Encounter>(
    condition.mostRecentCondition.encounter,
    "Encounter",
    mappedConsolidated
  );

  const practitionerReference = getReferenceResource<Practitioner>(
    condition.mostRecentCondition.recorder,
    "Practitioner",
    mappedConsolidated
  );

  return (
    <>
      <DetailsDisplay
        details={{
          name: tableRow.condition,
          status: condition.status,
        }}
      />
      {condition.sortedOccurrences && condition.sortedOccurrences.length > 1 && (
        <>
          <SidePanelSection title="Occurrences">
            {condition.sortedOccurrences.map((p, index) =>
              OccurenceDisplayPeriods(p.start, p.end, index)
            )}
          </SidePanelSection>
        </>
      )}
      <CodesDisplay code={condition.mostRecentCondition.code} />

      <ReferencesDisplay
        encounters={{ refs: encounterReference ? [encounterReference] : undefined }}
        practitioners={{
          title: "Recorder",
          refs: practitionerReference ? [practitionerReference] : undefined,
        }}
      />
    </>
  );
}
