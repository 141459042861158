import { AddIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Spinner } from "@chakra-ui/react";

export function EhrButtonLoading() {
  return <Spinner mt={2} ml={2} />;
}

export function EhrButtonSuccess() {
  return <CheckIcon mt={2} ml={2} />;
}

export function EhrButtonFailed() {
  return <CloseIcon mt={2} ml={2} />;
}

export function EhrButtonAdd() {
  return <AddIcon ml={2} />;
}

export function EhrButtonDisabled() {
  return null;
}
