import { Heading } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  addressSchema,
  Facility,
  FacilityCreate,
  facilityCreateSchema,
  MetriportMedicalApi,
} from "@metriport/api-sdk";
import { cloneDeep, get } from "lodash";
import { useState } from "react";
import { z } from "zod";
import { DEFAULT_COUNTRY } from "../../../domain/countries";
import { capture } from "../../../shared/capture";
import Constants from "../../shared/constants";
import DrawerForm from "../../shared/form/Drawer";
import { Input } from "../../shared/form/Input";
import { Select } from "../../shared/form/Select";
import useMetriportToast from "../shared-logic/useMetriportToast";

const facilitySchemaForm = facilityCreateSchema;
type FacilityForm = z.infer<typeof facilitySchemaForm>;

const formToCreate = (form: FacilityForm): FacilityCreate => {
  const formCopy = cloneDeep(form);
  return {
    ...formCopy,
    address: addressSchema.parse(formCopy.address),
  };
};

export default function FacilitiesForm({
  isOpen,
  selectedFacility,
  onClose,
  onFacilityAction,
  api,
}: {
  isOpen: boolean;
  selectedFacility?: Facility;
  onClose: () => void;
  onFacilityAction: (facility: Facility) => void;
  api: MetriportMedicalApi;
}) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const toast = useMetriportToast();

  const createOrUpdate = async (facility: FacilityForm) => {
    try {
      setIsSubmitting(true);

      let newFacility: Facility;
      if (selectedFacility) {
        newFacility = await api.updateFacility({
          ...facility,
          id: selectedFacility.id,
        });
      } else {
        newFacility = await api.createFacility(formToCreate(facility));
      }

      onFacilityAction(newFacility);
      toast.success({ title: "Facility saved." });
      onClose();
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const msg = err?.response?.data?.detail;
      const status = err?.response?.data?.status;
      status != 403 &&
        capture.error(err, {
          extra: {
            facilityId: selectedFacility?.id,
            facility,
            context: `facility.createOrUpdate`,
            err,
          },
        });
      toast.error({ title: msg });
    }
    setIsSubmitting(false);
  };

  return (
    <DrawerForm<FacilityForm>
      title={`${selectedFacility ? "Edit" : "Create"} Facility`}
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onSubmit={createOrUpdate}
      onClose={onClose}
      resolver={zodResolver(facilitySchemaForm)}
      defaultValues={{
        ...selectedFacility,
        address: {
          ...selectedFacility?.address,
          country: selectedFacility?.address.country || DEFAULT_COUNTRY,
        },
      }}
    >
      {({ register, formState: { errors } }) => (
        <>
          <Heading as="h4" size="sm">
            Basic Info
          </Heading>
          <Input {...register("name")} isRequired label="Name *" error={get(errors, "name")} />
          <Input
            type={"number"}
            {...register("npi")}
            isRequired
            label="National Provider Identifier (NPI) *"
            error={get(errors, "npi")}
            placeholder="Enter a valid 10-digit NPI. Ex: 1234567893"
          />
          <Input
            {...register("tin")}
            label="Tax Identification Number (TIN)"
            error={get(errors, "tin")}
          />
          <Heading as="h4" size="sm" pt={50}>
            Address
          </Heading>
          <Input
            {...register("address.addressLine1")}
            isRequired
            label="Address Line 1 *"
            error={get(errors, "address.addressLine1")}
          />
          <Input
            {...register("address.addressLine2")}
            label="Address Line 2"
            error={get(errors, "address.addressLine2")}
          />
          <Input
            {...register("address.city")}
            isRequired
            label="City *"
            error={get(errors, "address.city")}
          />
          <Select
            {...register("address.state")}
            isRequired
            label="State *"
            options={Constants.usStatesForAddress}
            placeholder="Select State"
            error={get(errors, "address.state")}
          />
          <Input
            {...register("address.zip")}
            isRequired
            label="Zip *"
            error={get(errors, "address.zip")}
          />
          <Input
            {...register("address.country")}
            isRequired
            label="Country *"
            error={get(errors, "address.country")}
            disabled={true}
          />
        </>
      )}
    </DrawerForm>
  );
}
