import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, HStack, Heading, Switch, Text, useColorModeValue } from "@chakra-ui/react";
import { ColDef } from "ag-grid-community";
import { MrFilterSetting } from "../../../../api/settings";
import { Button } from "../button";
import { NoPatientData } from "../no-data";
import { TableGrid } from "../table-grid";
import { DetailedReportRowData } from "./labs/shared";

export type ExpandedViewProps = {
  selectedGrouping: DetailedReportRowData | undefined;
  setSelectedGrouping: (r: DetailedReportRowData | undefined) => void;
  clearSelectedGrouping: () => void;
};

export type ViewModeProps = {
  viewMode: string;
  setViewMode: React.Dispatch<React.SetStateAction<string>>;
  handleToggle: () => void;
};

export function FhirSection({
  sectionId,
  sectionName,
  columnDefs,
  rowData,
  filters,
  actions,
  toggle,
  viewModeProps,
  expandedViewProps,
}: {
  sectionId: string;
  sectionName: string;
  columnDefs: ColDef[];
  rowData: object[];
  filters: MrFilterSetting | undefined;
  actions?: {
    onRowCellClicked: (content: object) => void;
  };
  toggle?: boolean;
  viewModeProps?: ViewModeProps;
  expandedViewProps?: ExpandedViewProps;
}) {
  const { viewMode, handleToggle } = viewModeProps || {};
  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      actions?.onRowCellClicked(e.data);
    },
    cellClassRules: {
      "cell-filter": params => {
        if (!params.value || !filters?.stringFilter) {
          return false;
        }

        const filtersArray = filters.stringFilter.split(",");

        const hasFilter = filtersArray.some(filter => {
          return params.value.toLowerCase().includes(filter.toLowerCase());
        });

        return hasFilter;
      },
    },
  };

  return (
    <Box
      id={sectionId}
      w={"100%"}
      mb={5}
      borderRadius={10}
      bg={useColorModeValue("white", "gray.800")}
      px={7}
      py={5}
      border="solid"
      borderColor={useColorModeValue("gray.200", "gray.700")}
    >
      <HStack mb={5}>
        <Heading>{sectionName}</Heading>
        {toggle && (
          <Box
            ms={3}
            border="solid"
            borderRadius={"30px"}
            borderColor={useColorModeValue("gray.200", "gray.700")}
          >
            <HStack ms={5} me={5} fontWeight={"medium"}>
              <Text m={2}>Individual</Text>
              <Switch
                isChecked={viewMode === "panel"}
                onChange={handleToggle}
                colorScheme={"blue"}
                size={"lg"}
              />
              <Text m={2}>Panel</Text>
            </HStack>
          </Box>
        )}
      </HStack>
      {expandedViewProps?.selectedGrouping && (
        <HStack mb={3}>
          <Button
            onClick={() => expandedViewProps.clearSelectedGrouping()}
            variant="ghost"
            leftIcon={<ArrowBackIcon />}
            aria-label="Back"
          >
            Back
          </Button>
          <Heading m={3} size={"md"}>
            {expandedViewProps?.selectedGrouping.name} -{" "}
            {expandedViewProps?.selectedGrouping.date ? (
              <Text as="span" fontSize="lg" fontWeight={"medium"}>
                {expandedViewProps?.selectedGrouping.date}
              </Text>
            ) : (
              ""
            )}
          </Heading>
        </HStack>
      )}
      {rowData.length > 0 ? (
        <TableGrid
          sectionTitle={sectionName}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={rowData}
        />
      ) : (
        <NoPatientData />
      )}
    </Box>
  );
}
