import posthog from "posthog-js/dist/module.full.no-external";
import { createContext, useContext } from "react";

posthog.init(
  process.env.REACT_APP_PUBLIC_POST_HOG_KEY ? process.env.REACT_APP_PUBLIC_POST_HOG_KEY : "",
  {
    api_host: process.env.REACT_APP_PUBLIC_POST_HOG_HOST,
    autocapture: false,
    capture_pageview: false,
    capture_pageleave: false,
    session_recording: {
      maskAllInputs: true,
    },
  }
);

export enum Features {
  medicalRequest = "medical request",
  organization = "organization",
  facility = "facility",
  patient = "patient(s)",
  link = "link",
  document = "document(s)",
  fhir = "fhir",
  subscription = "subscription",
  sandbox = "sandbox",
  invoices = "invoices",
  webhookUrl = "webhook url",
  user = "user",
  form = "form",
  apiKeyId = "api key",
  clientApiKeyId = "client api key",
  patientMatches = "patient matches",
}

export enum Actions {
  get = "get",
  create = "create",
  update = "update",
  delete = "delete",
  remove = "remove",
  download = "download",
  open = "open",
  close = "close",
  submit = "submit",
  revoke = "revoke",
  generate = "generate",
  test = "test",
  retry = "retry",
  signIn = "sign-in",
  signUp = "sign-up",
  cancel = "cancel",
  toggle = "toggle",
  view = "view",
  navigate = "navigate",
  manage = "manage",
  search = "search",
}

interface AnalyticsContext {
  emit: (eventAction: Actions, eventFeature: Features | string, properties?: object) => void;
  identify: (id: string, options?: object) => void;
  group: (cxId: string, options?: object) => void;
  reset: () => void;
  isIdentified: () => boolean;
}

const defaultContext: AnalyticsContext = {
  emit: () => undefined,
  identify: () => undefined,
  group: () => undefined,
  reset: () => undefined,
  isIdentified: () => false,
};

export const AnalyticsContext = createContext<AnalyticsContext>(defaultContext);

const POSTHOG_GROUP_ID = "customer";

export const AnalyticsProvider = ({
  enabled = true,
  children,
}: {
  enabled?: boolean;
  children: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}) => {
  return (
    <AnalyticsContext.Provider
      value={
        !enabled
          ? defaultContext
          : {
              emit: (
                eventAction: Actions,
                eventFeature: Features | string,
                properties?: object
              ) => {
                const event = `${eventAction} ${eventFeature}`;

                if (event) {
                  posthog.capture(event, {
                    ...properties,
                    platform: "dashboard",
                  });
                }
              },
              identify: (id: string, options?: object) => {
                posthog.identify(id, options);
              },
              group: (cxId: string, options?: object) => {
                posthog.group(POSTHOG_GROUP_ID, cxId, options);
              },
              reset: () => {
                posthog.reset();
              },
              isIdentified: () => {
                return posthog._isIdentified();
              },
            }
      }
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalyticsContext = () => useContext(AnalyticsContext);
